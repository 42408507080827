import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

// Erstelle die Galerie-Items basierend auf allen Trigger-Bildern
const galleryItems = [];
document.querySelectorAll('.pswp-gallery__trigger').forEach((trigger) => {
    galleryItems.push({
        src: trigger.getAttribute('data-pswp-src'),
        w: parseInt(trigger.getAttribute('data-pswp-width'), 10),
        h: parseInt(trigger.getAttribute('data-pswp-height'), 10),
        element: trigger // Speichert das HTML-Element, um darauf zuzugreifen
    });
});

const lightbox = new PhotoSwipeLightbox({
    gallery: '.pswp-gallery__trigger', // Klasse für die Galerie
    children: 'img', // Targeted Kinder-Element
    initialZoomLevel: 'fit',
    secondaryZoomLevel: 2,
    maxZoomLevel: 3,
    pswpModule: () => import('photoswipe')
});

// Füge einen Event-Listener zu jedem Bild hinzu, um die Galerie bei Klick zu öffnen
document.querySelectorAll('.pswp-gallery__trigger').forEach((trigger, index) => {
    trigger.addEventListener('click', (event) => {
        event.preventDefault();
        // Öffne die Galerie und starte bei dem angeklickten Bild
        lightbox.loadAndOpen(index, galleryItems);
    });
});

lightbox.init();
