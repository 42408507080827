$(document).ready(function() {
    /*SELECT 2
    $('select:not(.no-select2)').select2({
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    });

    $('.no-select2 select').select2('destroy');
    END SELECT 2*/

    //FUNCTIONS
    var slug = function(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
    
        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
    
        return str;
    };

    //SWIPER SLIDER
    var swiper = new Swiper(".swiper", {
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
        },
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    //END SWIPER SLIDER

    // Cooked Slider
    var swiper = new Swiper(".cooked-person-slider", {
        slidesPerView: "auto",
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 2000,
        },
    });
    // END Cooked Slider

    // INDEX CONTENTS
    var i = 0;
    var indexContent = [];
    var indexContentVar = [];
    $('.description h2').each(function() {
        var $this = $(this).text();
        var $this_slug = slug($this);
        var index = i++;
        $(this).attr('Id', index +'-'+ $this_slug);
        indexContent.push(index +'-'+ $this_slug);
        indexContentVar.push($this);
    });

    $.each(indexContent, function(index) {
        var realIndex = index + 1;
        $('#index-content ol').append(
            '<li><a class="text-white" href="#'+ this +'">'+ indexContentVar[index] +'</a></li>'
        );
    });
    // END - INDEX CONTENTS

    // Check if there's an anchor in the URL
    if (window.location.hash) {
        // Extract the anchor and scroll to the target element
        var target = $(window.location.hash);
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top -85
            }, 1000); // Duration of the animation in milliseconds
        }
    }
    // END - Check if there's an anchor in the URL

    // Listen for clicks on anchor links that stay within the same page
    $('a[href^="#"]').on('click', function(event) {
        // Prevent the default browser behavior
        event.preventDefault();

        // Get the target element from the anchor link
        var target = $($.attr(this, 'href'));

        // Check if the target element exists on the page
        if (target.length) {
            // Animate scrolling to the target element
            $('html, body').animate({
                scrollTop: target.offset().top - 85
            }, 1000); // Duration of the animation in milliseconds
        }
    });
    // END - Listen for clicks on anchor links that stay within the same page
});